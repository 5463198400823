var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-skeleton-loader',{staticClass:"mx-auto ml-2 mr-2",attrs:{"type":"card","loading":_vm.loading}},[_c('CommunityInfo',{attrs:{"communityInfo":_vm.communityInfo},on:{"onRelatedCommunityChanged":_vm.showRelatedCommunity}}),_c('InternalBreadcrumbs',{attrs:{"breadcrumbs":_vm.breadcrumbs}}),_c('v-card',{attrs:{"elevation":"0","outlined":"","color":"#f1f1f1"}},[_c('v-card-title',[_vm._v("New Proposal")]),_c('v-card-text',[_c('v-form',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-menu',{ref:"proposalDateMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Proposal Date*","error-messages":_vm.formattedProposalDateErrors,"prepend-icon":"mdi-calendar"},on:{"blur":function($event){_vm.proposalDate = _vm.parseDate(_vm.formattedProposalDate)}},model:{value:(_vm.formattedProposalDate),callback:function ($$v) {_vm.formattedProposalDate=$$v},expression:"formattedProposalDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.proposalDateMenu),callback:function ($$v) {_vm.proposalDateMenu=$$v},expression:"proposalDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.proposalDateMenu = false}},model:{value:(_vm.proposalDate),callback:function ($$v) {_vm.proposalDate=$$v},expression:"proposalDate"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Title","error-messages":_vm.titleErrors,"required":""},on:{"input":function($event){return _vm.$v.newProposal.title.$touch()},"blur":function($event){return _vm.$v.newProposal.title.$touch()}},model:{value:(_vm.newProposal.title),callback:function ($$v) {_vm.$set(_vm.newProposal, "title", $$v)},expression:"newProposal.title"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Amount","prefix":"$","error-messages":_vm.amountErrors,"rules":[
                    function (v) { return !!v || 'Field is required'; },
                    function (v) { return (v &&
                        /^(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/.test(
                          v
                        )) ||
                      'Use #,###.00 format'; }
                  ],"required":""},on:{"input":function($event){return _vm.$v.amount.$touch()},"blur":function($event){return _vm.$v.amount.$touch()}},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('MediaUploader',{key:_vm.attachment.id,staticClass:"pb-5",attrs:{"uploadType":"file","id":("multiFilePost_" + (_vm.attachment.id)),"index":_vm.attachment.id,"accept":_vm.accept,"multiple":false,"extensions":_vm.extensions,"sizeImage":10485760,"sizeVideo":104857600,"fileId":_vm.attachment.fileId,"file-url":_vm.attachment.url,"isUploading":_vm.isFileUploading},on:{"update:fileId":function($event){return _vm.$set(_vm.attachment, "fileId", $event)},"update:file-id":function($event){return _vm.$set(_vm.attachment, "fileId", $event)},"update:fileUrl":function($event){return _vm.$set(_vm.attachment, "url", $event)},"update:file-url":function($event){return _vm.$set(_vm.attachment, "url", $event)},"update:isUploading":function($event){_vm.isFileUploading=$event},"update:is-uploading":function($event){_vm.isFileUploading=$event},"onMultipleUploaded":_vm.onMultipleAddFile,"onChange":_vm.onChange,"onDeleteFile":_vm.onDeleteFile}})],1)],1),(!_vm.$v.attachment.url.required)?_c('div',{staticClass:"error--text"},[_vm._v(" Required. ")]):_vm._e(),_c('div',{staticClass:"mt-2"},[_c('v-btn',{staticClass:"orange-bg white--text",on:{"click":_vm.saveProposal}},[_vm._v(" Save Proposal ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }