<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
      <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />
      <v-card elevation="0" outlined color="#f1f1f1">
        <v-card-title>New Proposal</v-card-title>
        <v-card-text>
          <!-- Start Upload proposal section -->
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-menu
                    ref="proposalDateMenu"
                    v-model="proposalDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formattedProposalDate"
                        label="Proposal Date*"
                        :error-messages="formattedProposalDateErrors"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        @blur="proposalDate = parseDate(formattedProposalDate)"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="proposalDate"
                      no-title
                      @input="proposalDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newProposal.title"
                    label="Title"
                    :error-messages="titleErrors"
                    @input="$v.newProposal.title.$touch()"
                    @blur="$v.newProposal.title.$touch()"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="amount"
                    label="Amount"
                    prefix="$"
                    :error-messages="amountErrors"
                    :rules="[
                      v => !!v || 'Field is required',
                      v =>
                        (v &&
                          /^(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/.test(
                            v
                          )) ||
                        'Use #,###.00 format'
                    ]"
                    @input="$v.amount.$touch()"
                    @blur="$v.amount.$touch()"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <MediaUploader
                    class="pb-5"
                    uploadType="file"
                    :key="attachment.id"
                    :id="`multiFilePost_${attachment.id}`"
                    :index="attachment.id"
                    :accept="accept"
                    :multiple="false"
                    :extensions="extensions"
                    :sizeImage="10485760"
                    :sizeVideo="104857600"
                    :fileId.sync="attachment.fileId"
                    :file-url.sync="attachment.url"
                    :isUploading.sync="isFileUploading"
                    @onMultipleUploaded="onMultipleAddFile"
                    @onChange="onChange"
                    @onDeleteFile="onDeleteFile"
                  />
                </v-col>
              </v-row>
              <div class="error--text" v-if="!$v.attachment.url.required">
                Required.
              </div>
              <div class="mt-2">
                <v-btn class="orange-bg white--text" @click="saveProposal">
                  Save Proposal
                </v-btn>
              </div>
            </v-container>
          </v-form>
          <!-- End Upload proposal section -->
        </v-card-text>
      </v-card>

      <!-- </v-container> -->
    </v-skeleton-loader>
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { GET_QUERY, POST_DATA, API_CUSTOMERS } from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import MediaUploader from "@/view/components/imageUploader/mediaUploader.vue";

// import dataModels from "@/core/helpers/datamodels.js";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs,
    MediaUploader
  },
  data: () => ({
    communityInfo: {},
    newProposal: {
      title: "",
      amount: 0,
      attachment: {},
      proposalDate: new Date()
    },
    amount: "",
    proposalDateMenu: false,

    pageText: "",
    startingAfter: "",
    endingBefore: "",
    previousPage: 1,
    hasMore: false,
    isLoading: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: [],
    attachmentsObj: [{ id: 1, fileId: null, url: null }],
    attachment: { id: 1, fileId: null, url: null },
    isFileUploading: false,
    maxFilesCount: 1,
    proposalDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    formattedProposalDate: ""
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    // return true;
    return {
      amount: {
        required
      },
      formattedProposalDate: {
        required
      },
      proposalDate: {
        required
      },
      attachment: {
        url: {
          required
        }
      },
      newProposal: {
        title: {
          required
        },
        proposalDate: {
          required
        }
      }
    };
  },
  watch: {
    proposalDate: function() {
      this.formattedProposalDate = this.formatDate(this.proposalDate);
    }
  },
  async mounted() {
    this.formattedProposalDate = this.formatDate(this.proposalDate);
  },
  async created() {
    let self = this;
    self.loading = true;
    Promise.all([
      await this.getComunityInfo()
      // await this.getRecipients()
    ]).then(function() {
      self.loading = false;
    });
  },
  methods: {
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerDetails",
        params: { customerId: communityId }
      });

      // this.customerId = communityId;

      // this.getComunityInfo();
    },
    async getComunityInfo() {
      this.loading = true;

      let self = this;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            self.communityInfo = crud.getPopulatedComunityInfo(response.data);
            self.createBreadcrumb();
            self.setInternalBreadcrumbs();
          } else {
            self.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          self.loading = false;
        });
    },
    saveProposal() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.newProposal.id = this.attachment.fileId;
      this.newProposal.propertyId = this.customerId;
      this.newProposal.proposalDate = moment.utc(this.proposalDate);
      this.newProposal.amount = new Number(this.amount.replace(",", ""));
      this.newProposal.status = "Open";

      let self = this;
      this.$store
        .dispatch(POST_DATA, {
          listName: `${API_CUSTOMERS}/${this.customerId}/proposals`,
          data: this.newProposal
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            self.snackbarContent = "Proposal has been created";

            self.$router.push({
              name: "Proposals",
              params: { customerId: this.customerId }
            });
          } else {
            self.snackbarContent = response.data;
            self.snackbarColor = "red";
          }
          self.$snackbar.showMessage({
            content: self.snackbarContent,
            color: self.snackbarColor
          });
        });

      // this.saveCustomer();
    },
    onMultipleAddFile(files) {
      for (var i = 1; i < files.length; i++) {
        let max = Math.max(...this.attachmentsObj.map(i => i.id));
        this.attachmentsObj.push({
          id: (max += 1),
          url: files[i].fileUrl,
          fileId: files[i].id
        });
      }
      let max = Math.max(...this.attachmentsObj.map(i => i.id));
      this.attachmentsObj.push({ id: (max += 1), url: null });
      this.$nextTick(() => files.forEach(f => this.onChange(f)));
    },
    onChange(file) {
      if (file.fileUrl) {
        var found = this.attachmentsObj.find(f => f.url == file.fileUrl);
        found.fileId = file.id;
      }
    },
    onDeleteFile(fileData) {
      let index = this.attachmentsObj.findIndex(f => f.id === fileData.index);
      this.attachmentsObj.splice(index, 1);
    },
    setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Proposals",
          route: "/customers/" + this.customerId + "/proposals"
        },
        { title: "New Proposal" }
      ];
    },
    setShowSendToLine() {
      this.showSendToLine = this.sendTo === "YN team member or customer";
      this.showSendByBranch = this.sendTo === "Send by branch";
    },
    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Customers", route: "/customers" },
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Proposals",
          route: "/customers/" + this.customerId + "/proposals"
        },
        { title: "New Proposal" }
      ]);
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
    },
    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      return dateFormatter.formatDateUs(date);
      // return moment(date).format("MM/DD/YYYY");
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  },
  computed: {
    accept() {
      return "*/*";
    },
    extensions() {
      return "gif,jpg,jpeg,png,webp,mp4,mov,avi,mkv,mpg,vob,wmv,m4v,asf,srt,pdf,doc,docx";
    },
    formattedProposalDateErrors() {
      const errors = [];
      if (!this.$v.formattedProposalDate.$dirty) return errors;
      !this.$v.formattedProposalDate.required && errors.push("Required.");
      return errors;
    },
    titleErrors() {
      const errors = [];
      if (!this.$v.newProposal.title.$dirty) return errors;
      !this.$v.newProposal.title.required && errors.push("Required.");
      return errors;
    },
    amountErrors() {
      const errors = [];
      if (!this.$v.amount.$dirty) return errors;
      !this.$v.amount.required && errors.push("Required.");
      return errors;
    }
  }
};
</script>
